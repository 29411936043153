package dev.moetz.fakeitandmakeit.page

import dev.moetz.fakeitandmakeit.design.mFooter
import dev.moetz.fakeitandmakeit.model.Diary
import dev.moetz.fakeitandmakeit.util.ClientServiceManager
import dev.moetz.fakeitandmakeit.util.useStateFlow
import dev.moetz.materialize.mCard
import dev.moetz.materialize.mCol
import dev.moetz.materialize.mContainer
import dev.moetz.materialize.mHeader
import dev.moetz.materialize.mRow
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.builtins.ListSerializer
import react.FC
import react.Props
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.i
import react.dom.html.ReactHTML.img
import web.cssom.ClassName
import web.dom.document
import web.html.Loading
import web.window.window

external interface HomePageServiceProps : Props {
    var manager: HomePageServiceManager
}

class HomePageServiceManager : ClientServiceManager<List<Diary>>(
    websocketPath = "/api/v1/ws/diary",
    initialState = emptyList(),
    stateSerializer = ListSerializer(Diary.serializer()),
    localStorageConfig = null,
) {
    override suspend fun onRemoteStateReceived(state: List<Diary>) {
        super.onRemoteStateReceived(state)
        scope.launch {
            delay(250)
            val elems = document.querySelectorAll(".materialboxed")
            val instances = window.asDynamic().M.Materialbox.init(elems)
        }
    }
}

val HomePageService = FC<HomePageServiceProps> { props ->
    val connected = useStateFlow(props.manager.connectedStateFlow)
    val diaries = useStateFlow(props.manager.stateAsStateFlow)

    mHeader(
        title = "Travel Diary",
    )

    mContainer {
        mRow {
            mCol(small = 12) {
                h3 {
                    +"Public travel diary entries"
                }
            }
        }
        mRow {
            mCol(small = 12) {
                if (diaries.isEmpty()) {
                    i { +"No public diary entries yet." }
                } else {
                    diaries.forEach { diary ->
                        mCard(
                            title = diary.title,
                            content = {
                                mRow {
                                    mCol(small = 12) {
                                        +diary.dateTime.toLocalDateTime(TimeZone.currentSystemDefault()).toString()
                                        br()
                                    }
                                    mCol(small = 12) {
                                        if (diary.text.contains("\n")) {
                                            diary.text.split("\n").forEach { line ->
                                                +line
                                                br()
                                            }
                                        } else {
                                            +diary.text
                                        }
                                    }
                                    if (diary.images.isNotEmpty()) {
                                        mCol(small = 12) {
                                            br()
                                            +"Images:"
                                        }
                                        diary.images.forEach { image ->
                                            mCol(small = 4) {
                                                img {
                                                    loading = Loading.lazy
                                                    className = ClassName("responsive-img materialboxed")
                                                    src = image.url
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        )
                    }
                }
            }
        }
    }

    mFooter()
}
