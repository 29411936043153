package dev.moetz.fakeitandmakeit.design

import dev.moetz.materialize.mContainer
import dev.moetz.materialize.mRow
import react.ChildrenBuilder
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import web.cssom.ClassName
import web.window.WindowTarget

fun ChildrenBuilder.mFooter() {

    ReactHTML.footer {
        className = ClassName("page-footer blue")

        div {
            className = ClassName("footer-copyright")
            mContainer {
                mRow {
                    div {
                        className = ClassName("col l6 s6")

                        a {
                            className = ClassName("blue-text text-lighten-3")
                            href = "/docs"
                            target = WindowTarget._blank
                            +"API documentation"
                        }

//                        span {
//                            className = ClassName("red-text")
//                            +"❤"
//                        }

                    }


                    div {
                        className = ClassName("col l6 s6 right-align")

//                        a {
//                            className = ClassName("blue-text text-lighten-3")
//                            href = "/"
//                            +"Travel diary"
//                        }
//                        +" is "
//
//                        a {
//                            className = ClassName("blue-text text-lighten-3")
//                            href = "https://gitlab.moetz.dev/twitch/travel-diary"
//                            target = WindowTarget._blank
//                            +"closed source here"
//                        }
                    }
                }
            }
        }
    }
}